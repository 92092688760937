import React from "react";
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import PageBanner from "../components/Common/PageBanner"
import Icon1 from "../assets/images/bigdata-analytics/icon1.png";
import Icon2 from "../assets/images/bigdata-analytics/icon2.png";
import Icon3 from "../assets/images/bigdata-analytics/icon3.png";
import Icon4 from "../assets/images/bigdata-analytics/icon4.png";
import Icon5 from "../assets/images/bigdata-analytics/icon5.png";
import Icon6 from "../assets/images/bigdata-analytics/icon6.png";
import Rectangle from "../assets/images/bigdata-analytics/rectangle.png";
import WhyChoose from "../components/PCRepair/WhyChoose";
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import Services from "../components/BigdataAnalytics/Services";
import Logging from "../components/_App/logging";
const ourServices = () => (
    <Layout>
        <SEO title="Services" />
        <Logging title={"Services"} />
        <Navbar />
        <PageBanner pageTitle="Our Services" />
        <Services />
        <Footer />
    </Layout>
)

export default ourServices;
